







import { Component, Vue, Emit } from 'vue-property-decorator';

@Component({})
export default class EditAccountMail extends Vue {
  private status: number = 200;

  private stepNavi: StepNaviData[] = [
    {
      title: '情報変更',
    },
    {
      title: '変更完了',
    },
  ];

  @Emit()
  private setStatus(value: number): void {
    this.status = value;
  }

}
